import { useDocumentPreview } from "src/features/DocumentPreview"

const useFilePreview = () => {
  const { open } = useDocumentPreview()

  const preview = (file) => {
    open({
      document: {
        id: file.id,
        boxFileId: file.boxId,
        filename: file.name,
        contentPresent: file.contentPresent
      }
    })
  }

  return preview
}

export default useFilePreview
