import React from "react"

import useAiGenerateText from "src/hooks/ai/useGenerateText"

import { useUniversalAiSelector } from "../store"
import { Commands, Modes, Status, STREAMING_START_DELAY } from "../constants"

import { Base, Container } from "./commandComponents/Base"
import AreaOfFocus from "./AreaOfFocus"
import useFocusedItem from "./useFocusedItem"
import QueryInput from "./QueryInput"
import ThreadItems from "./ThreadItems"
import useThreadItems from "./useThreadItems"
import CurrentThreadItem from "./CurrentThreadItem"
import useStoredQuery from "./useStoredQuery"
import useCurrentThreadItem from "./useCurrentThreadItem"
import useCommand from "./useCommand"
import useRecentItems from "./useRecentItems"
import useSubmitQuery from "./useSubmitQuery"
import ThreadItemsContainer from "./ThreadItemsContainer"
import { Actions } from "./styles"
import { GenerateDraftMinutes } from "./commandComponents"
import useScrollToTop from "./useScrollToTop"
import { QueryTypes } from "./constants"

const Sidebar = () => {
  const { mode } = useUniversalAiSelector()
  const { command, setCommand } = useCommand()
  const { focusedItem, setFocusedItem, loading: focusedItemLoading } = useFocusedItem()
  const { query, setQuery } = useStoredQuery()
  const { threadItems, refetch: refetchThreadItems } = useThreadItems()

  const {
    currentThreadItem,
    isLoading,
    isPending,
    isInProgress,
    setCurrentThreadItem,
    updateCurrentThreadItem,
    updateExternalContentAnswer
  } = useCurrentThreadItem()

  const { ref: threadItemsRef, scrollToTop } = useScrollToTop()

  const {
    recentItems,
    loading: recentItemsLoading,
    refetch: refetchRecentItems
  } = useRecentItems()

  const { submitQuery, stopStreaming } = useSubmitQuery({
    focusedItem,
    onSubmit: () => {
      setCurrentThreadItem({
        query,
        focusable: focusedItem,
        status: Status.Loading
      })

      refetchThreadItems()
      scrollToTop()
    },
    onQueryTypeChange: (queryType) => {
      updateCurrentThreadItem({ queryType })
    },
    onChange: (answer) => {
      updateCurrentThreadItem({ answer, status: Status.InProgress })
    },
    onAnswerStreamingComplete: (answer) => {
      updateCurrentThreadItem({ answer, status: Status.Finished })
      refetchRecentItems()
    },
    onAnswerFinish: ({ sources, thinkingProcess }) => {
      updateCurrentThreadItem({ sources, thinkingProcess })
    },
    onSearchResultsComplete: (searchResults) => {
      updateCurrentThreadItem({ searchResults, status: Status.Finished })
    },
    onExternalContentAnswerLoad: (externalContentInstance) => {
      updateExternalContentAnswer({ status: Status.Loading, externalContentInstance })
    },
    onExternalContentAnswerComplete: ({ answerText, sources }) => {
      updateExternalContentAnswer({ answerText, sources, status: Status.Finished })
    }
  })

  const isSummariseCommand = command === Commands.SummariseAsText

  const { stopStreaming: stopSummarisationStreaming } = useAiGenerateText({
    skip: !isSummariseCommand || !isPending,
    objectId: focusedItem?.id,
    objectType: focusedItem?.type,
    startDelay: STREAMING_START_DELAY,
    onSubmit: () => {
      setCurrentThreadItem({
        query: command,
        queryType: QueryTypes.Query,
        focusable: focusedItem,
        status: Status.Loading
      })

      refetchThreadItems()
      scrollToTop()
    },
    onChange: (answer) => {
      updateCurrentThreadItem({ answer, status: Status.InProgress })
    },
    onComplete: (answer) => {
      updateCurrentThreadItem({ answer, status: Status.Finished })
      refetchRecentItems()
    }
  })

  const onQueryChange = (newQuery) => {
    setQuery(newQuery)
  }

  const onQuerySubmit = () => {
    setCommand(Commands.Query)
    submitQuery(query)
    setQuery("")
  }

  const onStopStreaming = () => {
    if (isSummariseCommand) {
      stopSummarisationStreaming()
    } else {
      stopStreaming()
    }

    updateCurrentThreadItem({ status: Status.Finished })
  }

  const onAreaOfFocusSelect = (newFocusedItem) => {
    // Bug fix: summarise then change Area of Focus and another summarisation is triggered (but should not)
    setCommand(Commands.Query)
    setFocusedItem(newFocusedItem)
  }

  const inputsDisabled = isLoading || isInProgress

  if (mode !== Modes.Sidebar) {
    return null
  }

  if (
    [Commands.GenerateExtensiveMinutes, Commands.GenerateShortMinutes].includes(
      command
    ) &&
    focusedItem
  ) {
    return (
      <GenerateDraftMinutes
        objectId={Number(focusedItem.id)}
        objectType={focusedItem.type}
        query={command}
      />
    )
  }

  return (
    <Base title="Knowa Q">
      <Container>
        <Actions>
          <AreaOfFocus
            focusedItem={focusedItem}
            focusedItemLoading={focusedItemLoading}
            recentItems={recentItems}
            recentItemsLoading={recentItemsLoading}
            onSelect={onAreaOfFocusSelect}
            disabled={inputsDisabled}
          />
          <QueryInput
            query={query}
            title={focusedItem?.title}
            inputDisabled={!focusedItem || inputsDisabled}
            inProgress={isInProgress}
            onChange={onQueryChange}
            onSubmit={onQuerySubmit}
            onStop={onStopStreaming}
          />
        </Actions>

        <ThreadItemsContainer ref={threadItemsRef}>
          {currentThreadItem && <CurrentThreadItem threadItem={currentThreadItem} />}

          {threadItems.length > 0 && <ThreadItems threadItems={threadItems} />}
        </ThreadItemsContainer>
      </Container>
    </Base>
  )
}

export default Sidebar
