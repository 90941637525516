import { bool, number, oneOf, shape, string } from "prop-types"

import { SORTING_DIRECTIONS_VALUES, SORTING_FIELDS_VALUES } from "./consts"

export const PermissionsType = shape({
  canCreateFolder: bool,
  canUploadFiles: bool,
  canDeleteFilesAndFolders: bool,
  canMoveFilesAndFolders: bool,
  canDownloadFilesAndFolders: bool
})

export const FolderItemType = shape({
  id: string.isRequired,
  boxId: string.isRequired,
  name: string.isRequired,
  type: string.isRequired,
  modifiedAt: string.isRequired,
  modifiedBy: string,
  size: string.isRequired,
  etag: string.isRequired
})

export const GroupType = shape({
  id: number.isRequired,
  boxFolderId: string.isRequired
})

export const SortingType = shape({
  field: oneOf(SORTING_FIELDS_VALUES).isRequired,
  direction: oneOf(SORTING_DIRECTIONS_VALUES).isRequired
})
