/* global $be */

import { DateTimeZonePickerComponent } from "legacy/components/meeting_packs/date_time_zone_picker.js.coffee"

export default class BaseMeetingPacksHandler {
  constructor() {
    this.setup = this.setup.bind(this)
    this.dateTimeZonePicker = new DateTimeZonePickerComponent()

    this.meetingPackName = $be("meeting-pack-name")
    this.meetingPackSubmit = $be("meeting-pack-submit")
  }

  setup() {
    this.dateTimeZonePicker.setup()

    this.meetingPackName
      .on("input", this.onMeetingPackNameInput.bind(this))
      .on("keypress", this.onMeetingPackNameKeypress.bind(this))

    this.onMeetingPackNameInput()
  }

  onMeetingPackNameInput() {
    const element = this.meetingPackName[0]

    element.value = element.value.replace(/\n/g, "")

    element.style.height = "auto"
    element.style.height = `${element.scrollHeight}px`
  }

  onMeetingPackNameKeypress(event) {
    if (event.charCode !== 13) return

    event.preventDefault()
    this.meetingPackSubmit.click()
  }
}
