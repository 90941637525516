import React from "react"
import { bool, func } from "prop-types"
import { useDrag, useDrop } from "react-dnd"

import FolderIcon from "src/styles/components/icons/FolderIcon"
import { splitFilename } from "src/helpers/string"
import FileIcon from "src/styles/components/FileIcon"
import { formatSize } from "src/helpers/number"

import { FolderItemType } from "../types"
import { DropTypes, FolderItemTypes } from "../consts"
import { usePermissionsContext } from "../PermissionsContext"

import {
  Row as StyledRow,
  IconCell,
  ModifiedByCell,
  ModifiedCell,
  NameCell,
  SelectionCell,
  SizeCell
} from "./styles"

const itemIcon = ({ type, name }) => {
  if (type === FolderItemTypes.Folder) {
    return <FolderIcon />
  }

  const { extension } = splitFilename(name)

  return <FileIcon extension={extension} />
}

const formatDateTime = (dateTime) => {
  const date = new Date(dateTime)

  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  }

  return date.toLocaleString("en-GB", options)
}

const Row = ({
  folderItem,
  isSelected,
  onClick,
  onNameClick,
  onSelectionChange,
  onMove
}) => {
  const permissions = usePermissionsContext()

  const [, dragRef] = useDrag(
    () => ({
      type: DropTypes.FolderItem,
      item: { id: folderItem.id, boxId: folderItem.boxId, type: folderItem.type },
      end: (item, monitor) => {
        const destinationFolder = monitor.getDropResult()

        if (item && destinationFolder) {
          onMove({ folderItem, destinationFolder })
        }
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [onMove]
  )

  const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    accept: [DropTypes.FolderItem],
    // Prevent dropping into a file and dropping a folder into itself
    canDrop: (_item, monitor) => {
      const draggedItem = monitor.getItem()

      if (folderItem.type !== FolderItemTypes.Folder) {
        return false
      }

      if (draggedItem.type === FolderItemTypes.File) {
        return true
      }

      return folderItem.id !== draggedItem.id
    },
    drop: () => {
      return { id: folderItem.id, boxId: folderItem.boxId, name: folderItem.name }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  const attachDragAndDropRef = (node) => {
    if (!permissions.canMoveFilesAndFolders) {
      return
    }

    dragRef(node)
    dropRef(node)
  }

  const handleClick = (e) => {
    e.stopPropagation()
    onClick()
  }

  const handleNameClick = (e) => {
    e.stopPropagation()
    onNameClick()
  }

  return (
    <StyledRow
      data-testid="row"
      onClick={handleClick}
      ref={attachDragAndDropRef}
      className={isOver && canDrop ? "drag-over" : undefined}
    >
      <SelectionCell>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(e) => onSelectionChange(e.target.checked)}
        />
      </SelectionCell>
      <IconCell data-testid="icon-cell">{itemIcon(folderItem)}</IconCell>
      <NameCell data-testid="name-cell" onClick={handleNameClick}>
        {folderItem.name}
      </NameCell>
      <ModifiedCell data-testid="modified-cell">
        {formatDateTime(folderItem.modifiedAt)}
      </ModifiedCell>
      <ModifiedByCell data-testid="modified-by-cell">
        {folderItem.modifiedBy}
      </ModifiedByCell>
      <SizeCell data-testid="size-cell">{formatSize(folderItem.size)}</SizeCell>
    </StyledRow>
  )
}

Row.propTypes = {
  folderItem: FolderItemType.isRequired,
  isSelected: bool.isRequired,
  onClick: func.isRequired,
  onNameClick: func.isRequired,
  onSelectionChange: func.isRequired,
  onMove: func.isRequired
}

export default Row
