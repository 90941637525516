import { captureException } from "@sentry/browser"
import { useCallback, useEffect, useState } from "react"

import { showFlashMessage } from "src/helpers/flash"
import useIsOpened from "src/hooks/useIsOpened"
import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"
import { getCurrentUserId } from "src/helpers/user"

import useDeleteItems from "./useDeleteItems"

const folderItemsToDependencyKey = (folderItems) => {
  return folderItems.map(({ id, type }) => `${type}-${id}`).join("-")
}

const useDeleteItemsConfirmModal = ({
  groupId,
  parentFolderId,
  folderItems,
  onComplete
}) => {
  const { open, close, isOpened } = useIsOpened()
  const { deleteItems } = useDeleteItems()
  const [deletionLog, setDeletionLog] = useState([])
  const [deleting, setDeleting] = useState(false)
  const [completed, setCompleted] = useState(false)

  const resetState = () => {
    setDeletionLog([])
    setDeleting(false)
    setCompleted(false)
  }

  const handleClose = () => {
    resetState()
    close()
  }

  useEffect(() => {
    if (folderItems.length === 0) return
    if (deletionLog.length !== folderItems.length) {
      return
    }

    setDeleting(false)
    setCompleted(true)
    onComplete()

    const isSomeError = deletionLog.some(({ errorMessage }) => !!errorMessage)

    if (isSomeError) {
      showFlashMessage(
        "warning",
        "Some items were not deleted, please, review errors below"
      )
    } else {
      showFlashMessage("success", "All items have been deleted successfully")
      handleClose()
    }
  }, [deletionLog.length, folderItems.length])

  const handleError = (error) => {
    setDeleting(false)
    setCompleted(true)
    captureException(error)
    showFlashMessage("danger", "Something went wrong, please try again later")
    handleClose()
  }

  const onSubmit = () => {
    setDeleting(true)
    const folderItemsInput = folderItems.map(({ boxId, type }) => ({ id: boxId, type }))

    deleteItems({ groupId, parentFolderId, folderItems: folderItemsInput }).catch(
      handleError
    )
  }

  const handleDeletionMessage = useCallback(
    ({ data }) => {
      const currentUserId = getCurrentUserId()
      if (data.userId !== currentUserId || data.parentFolderId !== parentFolderId) return

      const folderItem = folderItems.find(
        (item) => item.boxId === data.folderItemId && item.type === data.folderItemType
      )

      const deletedItem = {
        id: data.folderItemId,
        type: data.folderItemType,
        name: folderItem.name,
        errorMessage: data.errorMessage
      }

      setDeletionLog((currentDeletionLog) => [...currentDeletionLog, deletedItem])
    },
    [parentFolderId, folderItemsToDependencyKey(folderItems)]
  )

  useAblyChannel(CHANNELS.groups.folders, {
    onMessage: handleDeletionMessage
  })

  return {
    open,
    isOpened,
    onSubmit,
    deletionLog,
    deleting,
    completed,
    close: handleClose
  }
}

export default useDeleteItemsConfirmModal
