import { useCallback } from "react"
import { useApolloClient } from "@apollo/client"

import {
  FAIL_MESSAGE,
  FAIL_AUTHENTICATION_MESSAGE
} from "src/features/UniversalAi/constants"
import { CHANNELS } from "src/constants/ably"
import useChannelName from "src/features/UniversalAi/Sidebar/useChannelName"

import useRealtimeUpdates from "../useRealtimeUpdates"

import askAiQuestionMutation from "./askAiQuestionMutation.gql"
import summariseByQueryMutation from "./summariseByQueryMutation.gql"
import useStreaming from "./useStreaming"

const useAskQuestion = ({
  objectId,
  objectType,
  onChange,
  onStreamingComplete,
  onFinish,
  onExternalContentAnswerLoad,
  onExternalContentAnswerComplete,
  startDelay = 0
}) => {
  const apolloClient = useApolloClient()

  const channelName = useChannelName(
    `askQuestion-${objectType}`,
    CHANNELS.aiAnswer.askQuestion
  )

  const externalContentChannelName = useChannelName(
    `externalContentAnswer-${objectType}`,
    CHANNELS.aiAnswer.externalContentAnswer
  )

  const {
    setContent,
    setFinished,
    reset: resetStreaming,
    interrupt: stopStreaming
  } = useStreaming({
    onChange,
    channelName,
    startDelay,
    streamId: "askQuestion",
    onComplete: onStreamingComplete
  })

  const handleAnswer = ({ value, complete, error }) => {
    setContent(value || error)

    if (complete || error) {
      setFinished(true)
    }
  }

  const askQuestion = (question, { summarise }) => {
    resetStreaming()

    apolloClient
      .mutate({
        mutation: summarise ? summariseByQueryMutation : askAiQuestionMutation,
        variables: {
          question,
          objectId,
          objectType,
          channelName,
          externalContentChannelName
        }
      })
      .then((response) => {
        const { data } = response
        const responseData = summarise ? data?.summariseByQuery : data?.askAiQuestion

        if (!responseData?.success) {
          console.error("askAiQuestionMutation error", response)
          handleAnswer({ error: data?.errors || FAIL_MESSAGE })
        }
      })
      .catch((response) => {
        const error =
          response.graphQLErrors &&
          response.graphQLErrors[0]?.extensions?.code === "unauthorized"
            ? FAIL_AUTHENTICATION_MESSAGE
            : FAIL_MESSAGE

        console.error("askAiQuestionMutation error", response)
        handleAnswer({ error })
      })
  }

  const handleRealtimeUpdates = useCallback(
    (data) => {
      const isTargetObject =
        data.objectId.toString() === objectId?.toString() &&
        data.objectType === objectType

      if (!isTargetObject) return

      if (data.answer) {
        handleAnswer({ value: data.answer, complete: data.finished })

        if (data.finished && data.success)
          onFinish({ sources: data.sources, thinkingProcess: data.thinkingProcess })
      }

      if (!data.success) {
        handleAnswer({ error: data.error || FAIL_MESSAGE })
      }
    },
    [objectId, objectType]
  )

  useRealtimeUpdates(channelName, handleRealtimeUpdates)

  const handleExternalContentRealtimeUpdates = useCallback(
    (data) => {
      const isTargetObject =
        data.focusedItem.id.toString() === objectId.toString() &&
        data.focusedItem.type === objectType

      if (!isTargetObject) return

      if (data.finished) {
        onExternalContentAnswerComplete({
          answerText: data.answer,
          sources: data.sources
        })
      } else {
        onExternalContentAnswerLoad(data.externalContentInstance)
      }
    },
    [objectId, objectType]
  )

  useRealtimeUpdates(externalContentChannelName, handleExternalContentRealtimeUpdates)

  return { askQuestion, stopStreaming }
}

export default useAskQuestion
