import React, { useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { number } from "prop-types"
import pluralize from "pluralize"

import ContentLoader from "src/styles/components/ContentLoader"
import EmptyContent from "src/styles/components/EmptyContent"
import { CHANNELS } from "src/constants/ably"
import useRealtimeUpdates from "src/hooks/useRealtimeUpdates"

import vaultItemsQuery from "./vaultItems.gql"
import updateStaleFlagsMutation from "./updateStaleFlags.gql"
import ItemCard from "./ItemCard"

const ItemsList = ({ vaultId }) => {
  const { data, loading, refetch } = useQuery(vaultItemsQuery, {
    variables: { vaultId }
  })
  const [updateStaleFlags] = useMutation(updateStaleFlagsMutation, {
    variables: { vaultId }
  })

  const vaultItems = data?.supportingMaterialsVaultItems

  useEffect(() => {
    if (vaultItems?.length) updateStaleFlags()
  }, [vaultItems?.length])

  useRealtimeUpdates(CHANNELS.supportingMaterials, (publisherData) => {
    if (publisherData.vaultId === vaultId) {
      updateStaleFlags().then(refetch)
    }
  })

  if (loading) return <ContentLoader />
  if (!vaultItems?.length) return <EmptyContent>No items in the Vault</EmptyContent>

  return (
    <>
      <div className="d-flex">
        <div className="small">
          {vaultItems?.length}{" "}
          <span className="text-muted">
            {pluralize("item", vaultItems?.length, false)}
          </span>
        </div>
      </div>

      <div className="mt-3">
        {vaultItems.map((vaultItem) => (
          <ItemCard key={vaultItem.id} vaultItem={vaultItem} />
        ))}
      </div>
    </>
  )
}

ItemsList.propTypes = {
  vaultId: number.isRequired
}

export default ItemsList
