import React, { useContext } from "react"
import { Accordion, AccordionContext } from "react-bootstrap"
import { string, number, bool } from "prop-types"

import Icon from "src/styles/components/Icon"
import alertIcon from "images/alert-circle.svg"

import {
  CardTitle,
  CardTitleContainer,
  Badge,
  AlertIcon,
  AlertContainer,
  CardHeaderContainer
} from "./styles"

const Header = ({ itemName, documentsCount, eventKey, stale, staleNotificationText }) => {
  const currentEventKey = useContext(AccordionContext)
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as="div" eventKey={eventKey}>
      <CardHeaderContainer>
        <CardTitleContainer>
          <div className="d-flex align-items-center">
            <Icon
              type="dropdown-arrow"
              className={`d-block ${isCurrentEventKey && "icon-rotated"}`}
            />
            <CardTitle>{itemName}</CardTitle>
          </div>
          <span>
            <Badge>{documentsCount}</Badge>documents
            {stale && <AlertIcon height={16} src={alertIcon} className="ml-1" />}
          </span>
        </CardTitleContainer>

        {stale && (
          <AlertContainer>
            <AlertIcon src={alertIcon} className="mr-1" />
            {staleNotificationText}
          </AlertContainer>
        )}
      </CardHeaderContainer>
    </Accordion.Toggle>
  )
}

Header.propTypes = {
  itemName: string.isRequired,
  documentsCount: number.isRequired,
  eventKey: string.isRequired,
  stale: bool.isRequired,
  staleNotificationText: string
}

export default Header
