import React, { useState } from "react"
import { Modal } from "react-bootstrap"

import { Status } from "src/features/UniversalAi/constants"
import useMobileScreen from "src/hooks/useMobileScreen"
import useUniversalAi from "src/features/UniversalAi/useUniversalAi"
import Icon from "src/styles/components/Icon"
import useFeatureFlag from "src/hooks/useFeatureFlag"

import { ThreadItem as ThreadItemType } from "../propTypes"
import { QueryTypes } from "../constants"
import Query from "../Query"

import {
  YouLabel,
  KnowaQLabel,
  QueryContainer,
  ThinkingProcess,
  ThinkingProcessModalBody
} from "./styles"
import Answer from "./Answer"
import SearchResults from "./SearchResults"
import LoadingState from "./LoadingState"
import ExternalContentAnswer from "./ExternalContentAnswer"
import useExpandedExternalContentAnswer from "./useExpandedExternalContentAnswer"
import useExpandedExternalContentAnswerSources from "./useExpandedExternalContentAnswerSources"
import useOpenSources from "./useOpenSources"
import { LinkTitleMode } from "./Sources"

const ThreadItem = ({ threadItem }) => {
  const isMobile = useMobileScreen()
  const { close: closeSidebar } = useUniversalAi()

  const { opened: sourcesOpened, toggle: toggleSources } = useOpenSources(threadItem)

  const {
    expand: expandExternalContentAnswer,
    expanded: externalContentAnswerExpanded,
    collapse: collapseExternalContentAnswer
  } = useExpandedExternalContentAnswer(threadItem)

  const {
    toggle: toggleExternalContentAnswerSources,
    expanded: externalContentAnswerSourcesExpanded
  } = useExpandedExternalContentAnswerSources(threadItem)

  const [thinkingProcessOpened, setThinkingProcessOpened] = useState(false)
  const { flagValue: thinkingProcessEnabled } = useFeatureFlag(
    "ai-knowa-q-thinking-process-enabled"
  )

  const { query, queryType, answer, status, sources, externalContentAnswer, focusable } =
    threadItem
  const isQueryType =
    queryType === QueryTypes.Query || queryType === QueryTypes.SummariseByQuery
  const isKeywordType = queryType === QueryTypes.Keyword
  const isLoading = status === Status.Loading
  const isInProgress = status === Status.InProgress
  const hasResponse = (isQueryType && answer?.length) > 0 || (isKeywordType && !isLoading)

  const areSourcesLocal = sources.every((source) => {
    return (
      source.page &&
      source.sourceType === focusable.type &&
      String(source.sourceId) === focusable.id
    )
  })

  const linkTitleMode = areSourcesLocal ? LinkTitleMode.PageNumber : LinkTitleMode.Title

  const navigateToSource = (source, clickTarget) => {
    if (!source || source.sourceDeleted) return

    window.analytics.track("KnowaQ_Visit-Source", {
      source_id: source.sourceId,
      source_type: source.sourceType,
      link: source.link,
      click_target: clickTarget
    })

    window.Turbo.visit(source.link)
    if (isMobile) closeSidebar()
  }

  return (
    <div>
      <YouLabel>You</YouLabel>
      <QueryContainer>
        <Query
          query={query}
          queryType={queryType}
          focusableTitle={threadItem.focusable.title}
        />
      </QueryContainer>
      <KnowaQLabel>
        <span>Knowa Q</span>
        {thinkingProcessEnabled &&
          threadItem.thinkingProcess &&
          (!status || status === Status.Finished) && (
            <Icon type="info" onClick={() => setThinkingProcessOpened(true)} />
          )}
      </KnowaQLabel>

      <LoadingState
        focusableTitle={threadItem.focusable.title}
        focusableType={threadItem.focusable.type}
        loading={isLoading}
        hasResponse={hasResponse}
      />

      {!isLoading && isQueryType && (
        <Answer
          answerText={threadItem.answer}
          sources={threadItem.sources}
          sourcesExpanded={sourcesOpened}
          toggleSources={toggleSources}
          inProgress={status === Status.InProgress}
          linkTitleMode={linkTitleMode}
          onSourceNavigate={navigateToSource}
        />
      )}

      {externalContentAnswer && (
        <ExternalContentAnswer
          externalContentAnswer={externalContentAnswer}
          answerExpanded={externalContentAnswerExpanded}
          onAnswerExpand={expandExternalContentAnswer}
          onAnswerCollapse={collapseExternalContentAnswer}
          sourcesExpanded={externalContentAnswerSourcesExpanded}
          onSourcesToggle={toggleExternalContentAnswerSources}
          forceLoadingState={isLoading || isInProgress}
        />
      )}

      {!isLoading && isKeywordType && <SearchResults threadItem={threadItem} />}

      <Modal
        size="xl"
        show={thinkingProcessOpened}
        onHide={() => setThinkingProcessOpened(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Thinking process</span>
          </Modal.Title>
        </Modal.Header>
        <ThinkingProcessModalBody>
          <ThinkingProcess
            dangerouslySetInnerHTML={{ __html: threadItem.thinkingProcess }}
          />
        </ThinkingProcessModalBody>
      </Modal>
    </div>
  )
}

ThreadItem.propTypes = {
  threadItem: ThreadItemType.isRequired
}

export default ThreadItem
