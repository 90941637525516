import "react-dropdown-tree-select/dist/styles.css"

import React, { useEffect, useCallback, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { arrayOf, shape, number, bool, func, string } from "prop-types"

import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import { fetchSchemeFolders, moveBoxItems, copyBoxItems } from "src/resources/box/thunks"
import {
  addChildFolders,
  getNormalizedFolderOptions,
  getFolderId
} from "src/resources/box/helpers"
import { getSchemeOptionId } from "src/resources/schemes/helpers"
import { getAccountOptionId } from "src/resources/accounts/helpers"
import { selectSchemeOptions } from "src/resources/schemes/selectors"
import { selectGroupOptions } from "src/resources/groups/selectors"
import { selectAccountOptions } from "src/resources/accounts/selectors"
import SelectFolderModal from "src/components/SelectFolderModal"

const MoveCopyModal = ({
  selectedFolderItems,
  accountId: currentAccountId,
  group: currentGroup,
  isOpened,
  close
}) => {
  const dispatch = useDispatch()
  const selectedFolders = selectedFolderItems.filter((item) => item.type === "folder")
  const selectedFiles = selectedFolderItems.filter((item) => item.type === "file")
  const selectedFolderIds = selectedFolders.map(({ boxId }) => boxId)
  const groupOptions = useSelector(selectGroupOptions, shallowEqual)
  const sourceSchemeId = currentGroup.schemeId

  const schemeOptions = useSelector(selectSchemeOptions, shallowEqual)
  const accountOptions = useSelector(selectAccountOptions, shallowEqual)

  const currentScheme = schemeOptions.find(({ value }) => value === sourceSchemeId)
  const currentAccount = accountOptions.find(({ value }) => value === currentAccountId)
  const excludedGroupIds = groupOptions
    .filter(({ settings }) => !settings.documents)
    .map(({ id }) => id)

  const [isLoading, setLoading] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const [schemesFolders, setSchemesFolders] = useState({})
  const [targetFolder, setTargetFolder] = useState()
  const [targetScheme, setTargetScheme] = useState(currentScheme)
  const [targetAccount, setTargetAccount] = useState(currentAccount)
  const [fetchFolder, setFetchFolder] = useState({})
  const [fetchedFolders, setFetchedFolders] = useState([])

  const fetchFolderId = fetchFolder?.id
  const targetFolderId = getFolderId(targetFolder)
  const targetSchemeId = getSchemeOptionId(targetScheme)
  const targetAccountId = getAccountOptionId(targetAccount)

  const requestData = {
    selectedFiles,
    selectedFolders,
    targetFolderId,
    targetSchemeId,
    sourceSchemeId
  }

  const schemeFolders = schemesFolders[targetSchemeId] || []
  const setSchemeFolders = (folders) => {
    schemesFolders[targetSchemeId] = folders
    setSchemesFolders(schemesFolders)
  }

  const folderOptions = getNormalizedFolderOptions(
    schemeFolders,
    targetFolderId,
    selectedFolderIds,
    excludedGroupIds
  )

  const schemeOptionsByAccount = schemeOptions.filter(
    ({ accountId, accountIds }) =>
      accountId === targetAccountId || accountIds.includes(targetAccountId)
  )
  const accountIdsBySchemes = [
    ...new Set(
      schemeOptions.flatMap(({ accountId, accountIds }) =>
        accountId ? [accountId] : accountIds
      )
    )
  ]
  const accountOptionsBySchemes = accountOptions.filter(({ value }) =>
    accountIdsBySchemes.includes(value)
  )

  useEffect(() => {
    if (!isOpened || !targetSchemeId) return
    if (!fetchFolderId && fetchedFolders.includes(targetSchemeId)) return

    setLoading(true)
    dispatch(
      fetchSchemeFolders({
        schemeId: targetSchemeId,
        folderId: fetchFolderId,
        groupIds: [currentGroup.id],
        disabledFolderIds: selectedFolderIds
      })
    )
      .then((folders) => {
        if (fetchFolderId) {
          setSchemeFolders(addChildFolders(schemeFolders, fetchFolder, folders))
        } else {
          setSchemeFolders(folders)
          setFetchedFolders([
            ...new Set([...fetchedFolders, targetSchemeId, currentGroup.boxFolderId])
          ])
        }
      })
      .finally(() => setLoading(false))
  }, [dispatch, isOpened, targetSchemeId, fetchFolderId])

  const handleNodeToggle = (currentFolder) => {
    const folderId = getFolderId(currentFolder)

    if (currentFolder.expanded && !fetchedFolders.includes(folderId)) {
      setFetchFolder(currentFolder)
      setFetchedFolders([...fetchedFolders, folderId])
    }

    setSchemeFolders(addChildFolders(schemeFolders, currentFolder))
  }

  const handleAccountChange = useCallback(
    (accountOption) => {
      if (targetAccount !== accountOption) {
        setTargetAccount(accountOption)
        setTargetScheme(null)
        setTargetFolder(null)
        setFetchFolder(null)
      }
    },
    [targetAccount]
  )

  const handleSchemeChange = useCallback(
    (schemeOption) => {
      if (targetScheme !== schemeOption) {
        setTargetScheme(schemeOption)
        setTargetFolder(null)
        setFetchFolder(null)
      }
    },
    [targetScheme]
  )

  const startProcessing = () => {
    setProcessing(true)
    close()
  }

  const handleMove = useCallback(() => {
    if (!targetFolder) return

    startProcessing()
    dispatch(moveBoxItems(requestData))
  }, [dispatch, requestData])

  const handleCopy = useCallback(() => {
    if (!targetFolder) return

    startProcessing()
    dispatch(copyBoxItems(requestData))
  }, [dispatch, requestData])

  return (
    <SelectFolderModal
      onHide={close}
      show={isOpened}
      onAccountChange={handleAccountChange}
      selectedAccount={targetAccount}
      accounts={accountOptionsBySchemes}
      onSchemeChange={handleSchemeChange}
      selectedScheme={targetScheme}
      schemes={schemeOptionsByAccount}
      areFoldersLoading={isLoading}
      onNodeChange={setTargetFolder}
      onNodeToggle={handleNodeToggle}
      folders={folderOptions}
    >
      <SecondaryButton width="md" className="mr-3" onClick={close}>
        Cancel
      </SecondaryButton>
      <PrimaryButton
        width="md"
        className="mr-3"
        onClick={handleMove}
        disabled={isProcessing || !targetFolder}
      >
        Move
      </PrimaryButton>
      <PrimaryButton
        width="md"
        onClick={handleCopy}
        disabled={isProcessing || !targetFolder}
      >
        Copy
      </PrimaryButton>
    </SelectFolderModal>
  )
}

MoveCopyModal.propTypes = {
  selectedFolderItems: arrayOf(shape({ id: string.isRequired, type: string.isRequired }))
    .isRequired,
  accountId: number.isRequired,
  group: shape({
    id: number.isRequired,
    schemeId: number.isRequired
  }).isRequired,
  isOpened: bool.isRequired,
  close: func.isRequired
}

export default MoveCopyModal
