import { useMutation } from "@apollo/client"

import moveFolderItemMutation from "./moveFolderItemMutation.gql"

const useMoveFolderItemMutation = () => {
  const [mutate] = useMutation(moveFolderItemMutation)

  const move = ({ groupId, folderItem, destinationFolder }) => {
    const folderItemInput = {
      id: folderItem.boxId,
      type: folderItem.type,
      etag: folderItem.etag
    }

    return mutate({
      variables: {
        groupId,
        destinationFolderId: destinationFolder.boxId,
        folderItem: folderItemInput
      },
      refetchQueries: ["GroupFolderItems"],
      awaitRefetchQueries: true
    }).then((response) => {
      return response.data?.moveGroupFolderItem
    })
  }

  return move
}

export default useMoveFolderItemMutation
