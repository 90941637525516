import React from "react"
import { func, number } from "prop-types"

import { useGroupId } from "src/features/groups/DocumentsExplorer/GroupIdContext"
import { useCurrentFolderId } from "src/features/groups/DocumentsExplorer/CurrentFolderIdContext"
import CopyLink from "src/features/groups/DocumentsExplorer/Toolbar/CopyLink"
import { useSelectedFolderItems } from "src/features/groups/DocumentsExplorer/SelectedFolderItemsContext"

import Button from "../Button"
import { usePermissionsContext } from "../../PermissionsContext"

import {
  DeselectAllButton,
  Container,
  IconClose,
  ButtonsContainer,
  IconPencil,
  DeselectAllButtonLabel
} from "./styles"

const SelectedFolderItemsActions = ({
  count,
  onDelete,
  onDeselectAll,
  onMoveCopy,
  onDownload,
  onRename
}) => {
  const groupId = useGroupId()
  const currentFolderId = useCurrentFolderId()
  const selectedFolderItems = useSelectedFolderItems()
  const isOneItemSelected = selectedFolderItems.length === 1
  const selectedItem = selectedFolderItems[0]
  const isFolderSelected = selectedItem.type === "folder"
  const isFileSelected = selectedItem.type === "file"

  const folderId =
    isOneItemSelected && isFolderSelected ? selectedItem.boxId : currentFolderId
  const fileId = isOneItemSelected && isFileSelected ? selectedItem.boxId : null

  const permissions = usePermissionsContext()

  const {
    canDeleteFilesAndFolders,
    canMoveFilesAndFolders,
    canDownloadFilesAndFolders,
    canRenameFilesAndFolders
  } = permissions

  return (
    <Container>
      <ButtonsContainer>
        {canDeleteFilesAndFolders && (
          <Button iconType="trash" onClick={onDelete}>
            Delete
          </Button>
        )}
        {canMoveFilesAndFolders && (
          <Button iconType="exchange" onClick={onMoveCopy}>
            Move/Copy
          </Button>
        )}
        {canDownloadFilesAndFolders && (
          <Button iconType="arrow-down-hollow" onClick={onDownload}>
            Download
          </Button>
        )}
        {isOneItemSelected && (
          <>
            {canRenameFilesAndFolders && (
              <Button icon={<IconPencil type="pencil" />} onClick={onRename}>
                Rename
              </Button>
            )}

            <CopyLink groupId={groupId} folderId={folderId} fileId={fileId} />
          </>
        )}
      </ButtonsContainer>
      <DeselectAllButton onClick={onDeselectAll}>
        <IconClose>&times;</IconClose>
        <span>
          {count}&nbsp;<DeselectAllButtonLabel>selected</DeselectAllButtonLabel>
        </span>
      </DeselectAllButton>
    </Container>
  )
}

SelectedFolderItemsActions.propTypes = {
  count: number.isRequired,
  onDeselectAll: func.isRequired,
  onDelete: func.isRequired,
  onMoveCopy: func.isRequired,
  onDownload: func.isRequired,
  onRename: func.isRequired
}

export default SelectedFolderItemsActions
