import React from "react"
import { func, shape, string } from "prop-types"
import { useDrop } from "react-dnd"

import { Button } from "../styles"
import { DropTypes } from "../consts"
import { useCurrentFolderId } from "../CurrentFolderIdContext"

import { Name, SegmentContainer } from "./styles"

const Segment = ({ segment, onNavigate, children }) => {
  const currentFolderId = useCurrentFolderId()

  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [DropTypes.FolderItem],
      canDrop: () => segment.id !== currentFolderId,
      drop: () => {
        return { id: segment.id, boxId: segment.id, name: segment.name }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [segment.id, currentFolderId]
  )

  return (
    <SegmentContainer data-testid="segment">
      <Button
        ref={dropRef}
        onClick={() => onNavigate(segment.id)}
        className={isOver && canDrop ? "drag-over" : undefined}
      >
        <Name>{segment.name}</Name>
      </Button>

      {children}
    </SegmentContainer>
  )
}

Segment.propTypes = {
  segment: shape({ id: string.isRequired, name: string.isRequired }).isRequired,
  onNavigate: func.isRequired
}

export default Segment
