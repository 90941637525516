import { useCallback, useEffect, useRef } from "react"

import {
  annotationRenderer,
  connectAnnotationsMutationObserver,
  createSignDocument,
  setToolbarButtons,
  withSyncTimeout
} from "src/features/signedDocuments/AttachedSignedDocument/helpers"
import { updateSignatories } from "src/api/signedDocuments"
import { getCurrentUserId } from "src/helpers/user"
import { getCommonToolbarItems } from "src/features/signedDocuments/helpers"
import { generateUniqId } from "src/helpers/string"

import setupSignaturesStore from "./storedSignatures/setup"
import useStoredSignatures from "./storedSignatures/useStoredSignatures"

export function useSignedDocumentPSPDFKit({
  documentId,
  pspdfkitId,
  isSigned,
  documentName,
  jwt,
  containerId,
  updateDocument,
  setIsSigning,
  close,
  setIsSavingChanges,
  isCurrentUserAuthor
}) {
  const container = `#${containerId}`
  const annotationToSign = useRef(null)
  const latestCreatedAnnotations = useRef([])
  const currentUserId = getCurrentUserId()
  const { storedSignatures, deleteStoredSignature, updateStoredSignatures } =
    useStoredSignatures()

  const signDocument = useCallback(
    createSignDocument({ documentId, close, setIsSigning, updateDocument }),
    [documentId, close, setIsSigning, updateDocument]
  )

  useEffect(() => {
    window.PSPDFKit.load({
      container,
      initialViewState: new window.PSPDFKit.ViewState({
        readOnly: isSigned,
        showSignatureValidationStatus:
          window.PSPDFKit.ShowSignatureValidationStatusMode.IF_SIGNED
      }),
      editableAnnotationTypes: [
        window.PSPDFKit.Annotations.WidgetAnnotation,
        window.PSPDFKit.Annotations.InkAnnotation,
        window.PSPDFKit.Annotations.ImageAnnotation
      ],
      licenseKey: window.PSPDFKitKey,
      serverUrl: window.pspdfkitServerUrl,
      styleSheets: [window.pspdfkitESignatureStylesheetPath],
      documentId: pspdfkitId,
      authPayload: { jwt },
      instant: !isSigned,
      customRenderers: { Annotation: annotationRenderer }
    })
      .then((instance) => {
        if (isSigned) {
          return instance.setToolbarItems(() =>
            getCommonToolbarItems({ instance, documentName })
          )
        }

        const uniqueUserId = `${currentUserId}/${generateUniqId()}`
        instance.setAnnotationCreatorName(uniqueUserId)

        setToolbarButtons({
          instance,
          signDocument,
          isCurrentUserAuthor,
          documentName,
          storedSignatures
        })

        connectAnnotationsMutationObserver({
          contentDocument: instance.contentDocument,
          currentUserId
        })

        instance.addEventListener(
          "annotations.press",
          ({ annotation, preventDefault }) => {
            const { customData } = annotation
            const otherUserAnnotation =
              (customData?.signedUserId && customData?.signedUserId !== currentUserId) ||
              (customData?.assignedUserId && customData?.assignedUserId !== currentUserId)

            if (isSigned || otherUserAnnotation) {
              return preventDefault()
            }

            if (customData?.assignedUserId) {
              annotationToSign.current = annotation
            }
          }
        )

        instance.addEventListener("annotations.focus", (e) => {
          if (e.annotation.customData?.assignedUserId !== currentUserId) {
            e.nativeEvent.preventDefault()
          }
        })

        instance.addEventListener("annotations.create", (annotations) => {
          const newAnnotations = annotations.filter(
            (annotation) => annotation.creatorName === uniqueUserId
          )

          if (!newAnnotations.size) return

          const signatureFieldId = annotationToSign?.current?.id
          const signedUserId = annotationToSign?.current?.customData?.assignedUserId

          latestCreatedAnnotations.current = newAnnotations.toJS()

          setIsSavingChanges(true)
          // PSPDFKit fails to save image signature without timeout
          withSyncTimeout(() => {
            Promise.all(
              newAnnotations.map((annotation) =>
                instance.update(
                  annotation
                    .set("customData", { signatureFieldId, signedUserId })
                    .set("creatorName", null)
                )
              )
            ).then((updates) => {
              return instance
                .ensureChangesSaved(updates.flat())
                .finally(() => setIsSavingChanges(false))
            })
          })
        })

        instance.addEventListener("formFieldValues.update", () => {
          setToolbarButtons({ instance, signDocument, documentName, storedSignatures })
        })

        instance.addEventListener("formFieldValues.didSave", () => {
          setToolbarButtons({ instance, signDocument, documentName, storedSignatures })
          withSyncTimeout(() => updateSignatories({ documentId }))
        })

        instance.addEventListener("annotations.didSave", async () => {
          withSyncTimeout(() => updateSignatories({ documentId }))
        })

        instance.addEventListener("annotations.delete", () => {
          setToolbarButtons({ instance, signDocument, documentName, storedSignatures })

          withSyncTimeout(() => updateSignatories({ documentId }))
        })

        return instance
      })
      .then((instance) =>
        setupSignaturesStore({
          instance,
          storedSignatures,
          deleteStoredSignature,
          updateStoredSignatures
        })
      )

    return () => {
      if (document.querySelector(container)) window.PSPDFKit?.unload(container)
    }
  }, [documentId, jwt, pspdfkitId, containerId, storedSignatures])
}
